import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import base from './base.css'
import Wrapper from './site-wrapper'
import Nav from './nav'
import Scroll from './smooth-scroll'
import Footer from './footer'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    if (typeof window !== "undefined") {
      // eslint-disable-next-line global-require
      require("smooth-scroll")('a[href*="#"]')
    }

    return (
      <Wrapper>
        <Nav active={location.pathname === `/`? `active`: ''}
        activeGallery={location.pathname}
        activeGalleryItem={location.pathname}/>
        {children}
        <Scroll path={location.pathname}/>
        <Footer/>
      </Wrapper>
    )
  }
}

export default Template
